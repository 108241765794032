import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { endOfDay } from '@/utils/date'
import { PARTE_TRABAJO, ACCION_EJEC } from '@/utils/consts'

export default {
  async selectAsignadosCount (Vue, idtecnico, ffijada) {
    const apiFilter = new APIFilter()
    apiFilter
      .clear()
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGTE('ffijada', ffijada)
      .addLTE('ffijada', endOfDay(ffijada))
      .addExact('estado', PARTE_TRABAJO.estados.pendiente)
      .addExact('idtecnico', idtecnico)
    const resp = await Vue.$api.call(
      'parteTrabajo.select',
      { filter: apiFilter , wrapper: 'count' }
    )
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectAccionesCount (Vue, idtecnico, ffijada) {
    const apiFilter = new APIFilter()
    apiFilter
      .clear()
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGTE('ffijada', ffijada)
      .addLTE('ffijada', endOfDay(ffijada))
      .addExact('parte_trabajo_estado', PARTE_TRABAJO.estados.pendiente)
      .addExact('idest_accion_ejec', ACCION_EJEC.estados.pendiente)
      .addExact('idtecnico', idtecnico)
    const resp = await Vue.$api.call(
      'accionEjec.selectTotalesAcciones',
      { filter: apiFilter }
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
